/**
 * Strategy Page
 *
 * Contains the information for each climate strategy.
 */

import React from 'react';
import {inject, observer} from 'mobx-react';

import Closable from '../../../components/Closable';
import ContentPage from '../../../components/ContentPage';
import {Link} from "react-router-dom";
import CarrotButton from "../../../components/Navigation/CarrotButton";

const StrategyCategories = [
    {
        category: 'Parks, Operations, & Maintenance',
        className: 'pom',
    },

    {
        category: 'Ecosystem Functionality',
        className: 'ef',
    },

    {
        category: 'Users & Access',
        className: 'ua',
    },
];

export function StrategyPageContent({store, threatName, project}) {

    const strategies = store.strategiesByThreat(project)[threatName];

    if (!strategies || !strategies.length) return null;

    return (
        <div className="StrategyPageContent">

            {StrategyCategories.map(({category, className}, i) => {
                const _strategies = store.getStrategiesByCategory(strategies, category);

                const hasRelevantSources = (strategy) => {
                    return strategy.RelevantSources.length > 0;
                };
                const relevantSources = (strategy) => {
                    return strategy.RelevantSources.map((text, i) => {
                        return {
                            text,
                            url: strategy.SourceLinks[i]
                        }
                    });
                };

                const numStrategies = _strategies && _strategies.length;
                return (
                    <div key={category} className={`strategy ${className}`}>
                        <Closable title={(<h2>{category} ({numStrategies})</h2>)}
                                  closed={i !== 2}>
                            <div className="strategy__content">
                                {_strategies && _strategies.map((strategy, i) => (
                                    <div key={`${className}${i}`} className="strategy__column">
                                        <img className="img" src={`./img/strategies/${strategy['GraphicCode']}.svg`}/>
                                        <div className="strategy__item">
                                            <p dangerouslySetInnerHTML={{__html: strategy['Strategic Response']}}/>
                                            {hasRelevantSources(strategy) && <p className="strategy__links">
                                                Learn about...

                                                {relevantSources(strategy).map(({text, url}) => <a key={url}
                                                                                                   target="_blank"
                                                                                                   href={url}>{text}</a>)}
                                            </p>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Closable>
                    </div>
                );
            })}
        </div>
    );
}

@inject('store')
@observer
class StrategyPage extends React.Component {

    constructor(props) {
        super(props);

        this.threatName = props.store.deserialize(props.match.params.strategy);
        this.content = props.store.threatContent[this.threatName];
        this.threat = props.store.threats[this.threatName];

        this.strategies = props.store.strategiesByThreat()[this.threatName];

        if (!this.strategies) {
            console.error('COULD NOT FIND STRATEGY FOR: ', this.threatName);
            this.props.history.push('/strategies');
            // window.location.href = '/strategies';
        }
    }

    render() {
        const {store} = this.props;
        if (!this.content) {
            this.content = this.props.store.threatContent[this.threatName];
            if (!this.content) {
                return null;
            }
        }
        return (
            <ContentPage className="StrategyPage">
                <header>
                    <h1>{this.threat.displayName || this.threatName}</h1>
                    <p>
                        {this.content.abstract}
                    </p>
                    <p>
                        Below are a selection of design strategies that can be incorporated into park
                        design to mitigate negative effects of {this.threatName.toLowerCase()}.
                    </p>
                </header>

                <StrategyPageContent store={this.props.store} threatName={this.threatName}/>

                <div className={'map-link'}>
                    <CarrotButton
                        text={`Map "${this.threat.displayNameShort || this.threat.displayName || this.threatName}"`}
                        onClick={() => this.props.history.push(`/map/${store.serialize(this.threatName)}`)}/>
                    <CarrotButton text={'Show all strategies'} onClick={() => this.props.history.push(`/strategies`)}/>
                    <CarrotButton text={'Suggest a strategy'}
                                  onClick={() => this.props.history.push(`/suggest-strategy`)}/>
                </div>
            </ContentPage>
        );
    }
}


export default StrategyPage;
