/**
 * Projects Page
 *
 * Contains the pilot projects that exemplify the climate strategies.
 */

import React from 'react';
import {inject, observer} from 'mobx-react';
import {Link} from 'react-router-dom';

import ContentPage from '../../components/ContentPage';

import LargeParkGraphic from '../../assets/images/large-park.svg';
import MediumParkGraphic from '../../assets/images/medium-park.svg';
import SmallParkGraphic from '../../assets/images/small-park.svg';

@inject('store')
@observer
class ProjectsPage extends React.Component {

    render() {
        const {store} = this.props;
        const Evanston = store.getProject('Evanston')
        const Denver = store.getProject('Denver')
        const SLC = store.getProject('SLC')

        const quoted = (text) => {
            return <><span className={'big-quote'}>&#8220;</span>{text}<span
                className={'big-quote'}>&#8221;</span></>
        };
        return (
            <ContentPage className="ProjectsPage">
                <header>
                    <h1>Case Studies</h1>
                    <p>As part of this research project, Climate.Park.Change., Sasaki and NRPA worked with three cities
                        to identify a current or potential park in the Intermountain West to be a case study. Each city
                        participated in a two-hour workshop to help the research team better understand specific issues
                        related to climate change and strategies that would create a more resilient park.</p>
                    <p>West Bar Val Wood Park in Denver, Glendale Water Park Redevelopment in Salt Lake City, and Bear
                        Meadows Park in Evanston were selected as three Case Studies.
                    </p>
                    <p>Select a Case Study below to learn more:</p>
                </header>

                <div className="projects">
                    <div className="project">
                        <Link to="/projects/Denver">
                            <h2>Denver</h2>
                            <div className={'quote'}>{quoted(Denver.quote)}</div>
                            <div className={'organization'}>{Denver.organization}</div>
                            <img src={LargeParkGraphic} title="Large Park" alt="Large Park"/>
                        </Link>
                    </div>

                    <div className="project">
                        <Link to="/projects/SLC">
                            <h2>Salt Lake City</h2>
                            <div className={'quote'}>{quoted(SLC.quote)}</div>
                            <div className={'organization'}>{SLC.organization}</div>
                            <img src={MediumParkGraphic} title="Medium Park" alt="Medium Park"/>
                        </Link>
                    </div>

                    <div className="project">
                        <Link to="/projects/Evanston">
                            <h2>Evanston</h2>
                            <div className={'quote'}>{quoted(Evanston.quote)}</div>
                            <div className={'organization'}>{Evanston.organization}</div>
                            <img src={SmallParkGraphic} title="Small Park" alt="Small Park"/>
                        </Link>
                    </div>
                </div>
            </ContentPage>
        );
    }

}


export default ProjectsPage;
