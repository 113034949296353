/**
 * ContentPage
 *
 * Framing for pages with content
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';

import Logo from '../../components/Logo';
import Navigation from '../Navigation/Navigation';
import SasakiLogo from '../../assets/images/sasaki-logo.svg';
import NRPALogo from '../../assets/images/nrpa-logo.svg';


function ContentPage({ children, className }) {
    return (
        <div className={`ContentPage ${className}`}>
            <Navigation />

            <div className="page-content">
                {children}
            </div>

            <footer>
                <Link to="/">
                    <Logo size="large" />
                </Link>

                <div className="footer-links">
                    <a target="_blank" href="https://www.sasaki.com">
                        <img className="sasaki" src={SasakiLogo} alt="Leave to Sasaki website" title="Leave to Sasaki website" />
                    </a>

                    <a target="_blank" href="https://nrpa.org">
                        <img className="nrpa" src={NRPALogo} alt="Leave to NRPA website" title="Leave to NRPA website" />
                    </a>
                </div>
            </footer>
        </div>
    );
}


export default withRouter(observer(ContentPage));
