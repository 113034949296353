export default function Hamburger({ open, onToggle }) {
    return (
        <div className={`Hamburger ${open ? 'open' : ''}`}>
            <button onClick={() => onToggle(!open)}>
                <div/>
                <div/>
            </button>
        </div>
    );
}
