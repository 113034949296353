/**
 * Landing Page
 *
 * The index page.
 */

import React from 'react';
import {inject, observer} from 'mobx-react';
import {Link} from 'react-router-dom';

import Logo from '../../components/Logo';
import infoIcon from '../../assets/images/info.svg';
import Autosuggest from 'react-autosuggest';

@inject('store')
@observer
class CountyAutoSuggest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            suggestions: []
        };
    }

    onChange = (event, {newValue}) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };
    onSuggestionSelected = (sel, data) => {
        console.log('SELECTED', data.suggestion);
        this.props.store.setSelectedCounty(data.suggestion);
        this.props.history.push(`/map`);
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    getSuggestions(value) {
        const counties = this.props.store.countyList;

        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : counties.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    }

    render() {
        const getSuggestionValue = suggestion => `${suggestion.name}, ${suggestion.state}`;

        const renderSuggestion = suggestion => (
            <div>
                {`${suggestion.name}, ${suggestion.state}`}
            </div>
        );

        const {value, suggestions} = this.state;

        const inputProps = {
            placeholder: 'Find your Intermountain West county ...',
            value,
            onChange: this.onChange
        };

        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionSelected={this.onSuggestionSelected}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
        );
    }
}

@inject('store')
@observer
class LandingPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            query: '',
        };
    }

    render() {
        const {selectedCounty} = this.props.store;
        return (
            <div className="LandingPage">
                <Link to="/about" className="about-link">
                    <img src={infoIcon} alt="Read about the project" title="Read about the project"/>
                    <div className={'about-text'}>About</div>
                </Link>

                <div className="page-content">
                    <h1>The climate is changing<span>.</span> We can change with it<span>.</span></h1>

                    <div className="search-bar">
                        <div className="search-input">
                            <span className="carrot">&gt;</span>
                            <CountyAutoSuggest history={this.props.history}/>
                        </div>

                        <div className="search-footer">
                            <span className="analyze">Analyze the change in your county</span>
                            <div className="explore">
                                <span><span className="carrot">&gt;</span> Explore:</span>
                                <Link to="/map">Map</Link><Link to="/strategies">Strategies</Link><Link
                                to="/projects">Case Studies</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <footer>
                    <Logo size="large"/>

                    <ul className="footer-links">
                        <li>Powered by <a href="https://www.sasaki.com" target="_blank">Sasaki</a><span
                            className="separator"> = </span></li>
                        <li>Partnered with <a href="https://nrpa.org" target="_blank">NRPA</a></li>
                    </ul>
                </footer>
            </div>
        );
    }

}


export default LandingPage;
