/**
 * Strategies Page
 *
 * Contains the climate strategies index.
 */

import React from 'react';
import {inject, observer} from 'mobx-react';

import ContentPage from '../../components/ContentPage';
import ContactForm from "../../components/ContactForm/ContactForm";

@inject('store')
@observer
class SuggestStrategy extends React.Component {

    render() {
        const {categorizedThreats: threats, serialize} = this.props.store;

        return (
            <ContentPage className="SuggestStrategy">
                <div className="header">
                    <h1>Suggest a strategy</h1>
                    We can all benefit from sharing ideas and solutions. We'd love to hear your ideas about new
                    strategies or suggestions to improve the content on this site.
                </div>

                <ContactForm type={'suggest strategy'} includeUrl={true} />
            </ContentPage>
        );
    }
}

export default SuggestStrategy;
