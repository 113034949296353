/**
 * Map Page
 *
 * Contains the mapped data
 */

import React from 'react';
import {inject, observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Select, {components} from 'react-select';

import Closable from '../../components/Closable';
import MapViewer from './MapViewer';
import Logo from '../../components/Logo';
import Navigation from '../../components/Navigation/Navigation';
import {withRouter} from "react-router";
import CarrotButton from "../../components/Navigation/CarrotButton";
//
// const Option = props => (
//     <Link to={`/map/${encodeURIComponent(props.data.value.split(' ').join('-'))}`}>
//         <components.Option {...props} />
//     </Link>
// );

const renderers = {
    list: (props) => {
        if (props.ordered) {
            return <ol>{props.children}</ol>;
        } else {
            const content = props.children[0].props.children;

            return (
                <Closable closed={true} title={(<h2>{content[0]}</h2>)}>
                    {content.slice(1, content.length)}
                </Closable>
            );
        }
    }
};


@inject('store')
@observer
class MapPage extends React.Component {

    constructor(props) {
        super(props);

        const threatsArr = Object.keys(props.store.threats);
        const activeLayer = props.store.deserialize(props.match.params.threat);

        if (props.match.params.threat === undefined) {
            this.navigateTo(threatsArr[0]);
            //CODE REVIEW: this doesn't work with HashRouter
            // window.location.href = `/map/${props.store.serialize(threats[0])}`;
        } else {
            if (!threatsArr.includes(activeLayer)) {
                this.navigateTo(threatsArr[0]);
                //CODE REVIEW: this doesn't work with HashRouter
                // window.location.href = `/map/${props.store.serialize(threats[0])}`;
            }
        }

        this.state = {
            activeLayer,
            expanded: false,
        };

        this.options = threatsArr.map(threat => ({
            value: threat,
            label: props.store.threats[threat].displayName || threat,
        }));
    }

    navigateTo(threatId) {
        const {store, history} = this.props;
        history.push(`/map/${store.serialize(threatId)}`);
    }

    render() {
        const {store, history} = this.props;

        const newActiveLayer = store.deserialize(this.props.match.params.threat)
        let {activeLayer, expanded} = this.state;
        const {threats, threatContent, selectedCounty} = store;

        const threatsArr = Object.keys(threats)

        const threat = threats[activeLayer] || threatsArr[0];
        const content = threatContent[activeLayer];

        if (!threat) {
            return null;
            // const threatsArr = Object.keys(threats);
            //redirect
            // history.push(`/map/${store.serialize(threatsArr[0])}`);
        }
        if (activeLayer !== newActiveLayer) {
            this.setState({
                activeLayer: newActiveLayer
            })
            activeLayer = newActiveLayer;
        }

        // console.log('RERENDER MAP: ', activeLayer, threat.displayName, expanded, document.body.clientWidth)

        let displayName = threat.displayName || activeLayer;
        return (
            <div className="MapPage">
                <Link to="/" className="logo-link">
                    <Logo/>
                </Link>

                <Navigation/>

                <MapViewer store={store} popupAllowed={!expanded || document.body.clientWidth > 800}
                           selectedCounty={selectedCounty} activeLayer={threat.layer} threats={threats}/>

                <div className={`threat-viewer ${expanded ? 'expanded' : ''}`}>
                    <div className="threat-viewer-header">
                        <Select
                            menuPlacement={expanded ? 'bottom' : 'top'}
                            className="threat-selector"
                            classNamePrefix="threat-selector"
                            // components={{ Option }}
                            onChange={(a) => {
                                this.navigateTo(a.value);
                            }}
                            value={{value: activeLayer, label: displayName}}
                            options={this.options}
                        />

                        {expanded ?
                            <button className="close-expanded"
                                    onClick={() => this.setState({expanded: false})}>_</button> :
                            <button className="close-expanded"
                                    onClick={() => this.setState({expanded: true})}>&#x25A1;</button>}
                    </div>

                    <div className="threat-content">
                        {content ? <>
                            <div className={'threat-body'}>
                                {content.abstract}
                            </div>

                            {!expanded &&
                            <CarrotButton text={'Read more'} onClick={() => this.setState({expanded: true})}/>}

                            <div className="threat-content-body">
                                <ReactMarkdown renderers={renderers} children={content.body} linkTarget={'_blank'}/>
                            </div>
                        </> : (
                            <div className="loading">
                                Loading
                            </div>
                        )}
                    </div>

                    <div className={'cross-link'}>
                        <CarrotButton text={'View strategies'}
                                      onClick={() => this.props.history.push(`/strategies/${store.serialize(activeLayer)}`)}/>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(MapPage);
