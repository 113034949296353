import React from 'react';
import {interpretLayerStyleColors} from "./legendUtils";

function LegendItem(props) {
    return <div className={'LegendItem'}>
        <div className={'color-swatch'} style={{backgroundColor: props.color}}/>
        <div className={'item-name'}>{props.name}</div>
    </div>;
}

export default class MapLegend extends React.Component {

    constructor(props) {
        super(props);

    }

    formatData() {
        const {legendData} = this.props;
        const {layerStyles} = legendData;
        return interpretLayerStyleColors(layerStyles).map(({num, color}, i) => {
            return {id: i, name: legendData.format(num), color}
        });
    }

    render() {
        const {legendData, collapseClick} = this.props;
        if (!legendData) return null;
        return (
            <div className="MapLegend">
                <div className={'metric-info'}>{legendData.metric}</div>
                <div className={'legend'}>
                    {this.formatData().map(item => <LegendItem key={item.id} color={item.color} name={item.name}/>)}
                    <div className={'simple-scale'}>
                        <div className="top">
                            {legendData.higherIsBetter ? 'Worst' : 'Best'}
                        </div>
                        <div className="bottom">
                            {legendData.higherIsBetter ? 'Best' : 'Worst'}
                        </div>
                    </div>
                </div>
                {collapseClick && <button onClick={collapseClick}>_</button>}
            </div>
        );
    }
}
