/**
 * Map Viewer
 *
 * Contains the mapped data
 */

import {inject, observer} from 'mobx-react';
import React from 'react';
import {AutoSizer} from 'react-virtualized';
import mapboxgl from '!mapbox-gl';//Note: !mapbox-gl = fix for mapbox bug: https://github.com/mapbox/mapbox-gl-js/issues/10173
import MapLegend from "./MapLegend";
import ReactDOM from 'react-dom';
import MapPopupList from "./MapPopupList";

mapboxgl.accessToken = "pk.eyJ1IjoidGFkaXJhbWFuIiwiYSI6IktzUnNGa28ifQ.PY_hnRMhS94SZmIR2AIgug";

@inject('store')
@observer
class MapViewer extends React.Component {

    constructor(props) {
        super(props);
        const {threats} = this.props;

        this.layers = Object.values(threats).map(threat => threat.layer).filter(layer => !!layer);
        this.layerStyles = {};
        this.map = null;
        this.popup = null;

        this.activeLayer = null;

        this.state = {
            legendData: null,
            legendVisible: document.body.clientWidth > 800,
        }
    }

    setRef(el) {
        if (!el) return;
        setTimeout(() => {
            this.setupMap(el)
        }, 100);
    }

    setupMap(el) {
        const {activeLayer, selectedCounty, store} = this.props;

        const setActiveLayer = () => {
            console.log('setActiveLayer', this.layerStyles, activeLayer, this.activeLayer);
            if (Object.keys(this.layerStyles).length === 0) return;
            if (this.activeLayer === activeLayer) return;
            try {
                console.log('setLayoutProperty', this.layers);
                this.layers.forEach(layer => this.map.setLayoutProperty(layer, 'visibility', layer === activeLayer ? 'visible' : 'none'));
                if (this.activeLayer) {
                    this.map.off('click', this.activeLayer);
                }
                this.activeLayer = activeLayer;
                this.map.on('click', activeLayer, (e) => {
                    console.log('map CLICK', this.props.selectedCounty, e.features);

                    if (!store) return;
                    if (!e.features) return;
                    if (e.features.length === 0) return;

                    const countyMatch = store.findCountyMatch(e.features[0].properties.GEOID);
                    store.setSelectedCounty(countyMatch);

                    this.showPopup(countyMatch);
                });
                const {threats} = this.props;
                const activeThreat = Object.values(threats).find(threat => threat.layer === activeLayer);
                this.setState({
                    legendData: {
                        ...activeThreat.legend,
                        layerStyles: this.layerStyles[activeLayer]
                    }
                })
            } catch (e) {
                console.log('COULD NOT SET');
                //no worries if we hit this... mapbox just not ready yet -- but hard to know because neither loaded(), nor isStyleLoaded() seems to work as expected
            }
        };

        if (this.map && activeLayer) {
            //Loaded test + callback not reliable - so just call setActiveLayer for either path...
            setActiveLayer();
            this.map.on('load', () => {
                setActiveLayer();
            });
            if (selectedCounty) {
                this.showPopup(selectedCounty);
            }
        } else {
            if (el) {
                const map = new mapboxgl.Map({
                    container: el.id,
                    style: 'mapbox://styles/tadiraman/ckko67cap0qds17n5cfkl5ylo',
                    maxZoom: 8,
                    minZoom: 4,
                    maxBounds: [
                        [-129, 20.0],
                        [-66.351913, 50.285006]
                    ],
                });

                const popup = new mapboxgl.Popup({
                    closeButton: false
                });

                popup.on('close', (e) => {
                    const {popupAllowed} = this.props;
                    console.log('popup CLOSED', popupAllowed, e);
                    if (popupAllowed) {
                        // store.setSelectedCounty(null);
                    }
                });

                if (activeLayer) {
                    map.on('load', () => {
                        const styleJson = map.getStyle();
                        // console.log('styleJson', styleJson);
                        styleJson.layers.forEach((layerStyle, i) => {
                            if (this.layers.includes(layerStyle.id)) {
                                this.layerStyles[layerStyle.id] = layerStyle.paint["fill-color"];
                            }
                        });
                        console.log('layerStyles', this.layerStyles);
                        setActiveLayer();

                        // map.setLayoutProperty(activeLayer, 'visibility', 'visible');
                    });
                }

                this.map = map;
                this.popup = popup;

                if (selectedCounty) {
                    this.showPopup(selectedCounty);
                }

            }
        }
    }

    showPopup(county) {
        if (!county || !county.lngLat || !this.popup) return;
        const {store, activeLayer, popupAllowed} = this.props;
        if (!popupAllowed) return;
        const placeholder = document.createElement('div');
        ReactDOM.render(<MapPopupList legendData={this.state.legendData} store={store} activeLayer={activeLayer}
                                      county={county}/>, placeholder);
        this.popup
            .setLngLat(county.lngLat)
            .setDOMContent(placeholder)
            // .setText(`${county.name}`)
            .addTo(this.map);
    }

    render() {
        const {popupAllowed, selectedCounty} = this.props;

        console.log('POPUP ALLOWED: ', selectedCounty, popupAllowed, this.popup);
        if (selectedCounty) {
            if (!popupAllowed) {
                if (this.popup) this.popup.remove();
            } else {
                this.showPopup(selectedCounty);
            }
        }

        return (
            <div className="MapViewer">
                <AutoSizer>
                    {(({width, height}) => width === 0 || height === 0 ? null : (
                        <div id="map" style={{width, height}} ref={el => this.setRef(el)}/>
                    ))}
                </AutoSizer>
                {popupAllowed && this.state.legendVisible ? <MapLegend legendData={this.state.legendData}
                                                                       collapseClick={() => this.setState({legendVisible: false})}/> :
                    <button className={'legend-toggle'}
                            onClick={() => this.setState({legendVisible: true})}>{'Legend'}</button>}
            </div>
        );
    }
}

export default MapViewer;
