/**
 * Logo
 *
 * Either a 'small' or 'large' CPC logo.
 */

import React from 'react'


export default function Logo({ size = 'small' }) {
    if (size !== 'small' && size !== 'large') {
        throw new Error("logo size must be 'small' or 'large'");
    }

    return (
        <div className={`Logo Logo--${size}`}>
            climate<span>.</span><br/>
            park<span>.</span><br/>
            change<span>.</span>
        </div>
    )
}
