/**
 * Navigation
 *
 * Provides the links to navigate around the site
 */

import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';

import Hamburger from './Hamburger';


@inject('router')
@observer
class Navigation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    render() {
        const { isOpen } = this.state;
        const { hash } = this.props.router.location;

        const [ht, pathname] = hash.split('/');
        console.log(pathname);
        return (
            <nav className={`Navigation ${isOpen ? 'open' : ''}`}>
                <Hamburger open={isOpen} onToggle={isOpen => this.setState({ isOpen })}/>
                <ul>
                    <li>
                        <Link to={'/'}>Home</Link>
                    </li>
                    <li>
                        {pathname === 'about' && <span className="carrot">&gt; </span>}
                        <Link to={'/about'}>About</Link>
                    </li>
                    <li>
                        {pathname === 'projects' && <span className="carrot">&gt; </span>}
                        <Link to={'/projects'}>Case Studies</Link>
                    </li>
                    <li>
                        {pathname === 'strategies' && <span className="carrot">&gt; </span>}

                        <Link to={'/strategies'}>Strategies</Link>
                    </li>
                    <li>
                        {pathname === 'map' && <span className="carrot">&gt; </span>}

                        <Link to={'/map'}>Explore Map</Link>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default withRouter(Navigation);
