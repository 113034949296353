/**
 * About Page
 *
 * Contains the content explaining the project
 */

import React from 'react';
import {inject, observer} from 'mobx-react';

import ContentPage from '../../components/ContentPage';
import ReactMarkdown from "react-markdown";
import ContactForm from "../../components/ContactForm/ContactForm";

const renderers = {
    image: (props) => {
        return <img alt={props.alt} src={`./img/${props.src}`}/>;
    }
};


@inject('store')
@observer
class AboutPage extends React.Component {

    render() {
        const {aboutContent} = this.props.store;

        // console.log('RENDER ABOUT');
        return (
            <ContentPage className="AboutPage">
                <ReactMarkdown renderers={renderers} children={aboutContent} linkTarget={'_blank'}/>
                <div>
                    <div className="header">
                        <h2>Additional Mapping and Data Resources</h2>
                    </div>
                    <p>NRPA has sourced additional free resources that provide data and maps to assist park and
                        recreation agencies in operating more efficiently that can further support the tools in
                        Climate.Park.Change., including creating a master plan, facilitating long-range planning, or
                        building a park and facility maintenance schedule.</p>
                    <a className={'strategy-style'}
                       href={'https://www.nrpa.org/publications-research/mapping-and-data-resources/'} target="_blank">
                        Mapping and Data Resources
                    </a>
                </div>
                <div>
                    <div className="header">
                        <h1>Video Walkthrough</h1>
                    </div>
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/541831135" width="640" height="360"
                            frameBorder="0" allowFullScreen/>
                </div>
                <div>
                    <div className="header">
                        <h1>Contact Us</h1>
                        We can all benefit from sharing ideas and solutions. We'd love to hear your comments and ideas
                        about how to improve this site, or any other feedback you wish to share.
                    </div>
                    <ContactForm type={'contact us'}/>
                </div>
            </ContentPage>
        );
    }

}


export default AboutPage;
