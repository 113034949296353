import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import {BrowserRouter, HashRouter} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
//import reportWebVitals from './reportWebVitals';

import './index.scss';
import App from './App';
import AppStore from './store';


const store = new AppStore();
const router = new RouterStore();

const browserHistory = createBrowserHistory();
syncHistoryWithStore(browserHistory, router);

ReactDOM.render(
    <Provider store={store} router={router}>
        <HashRouter>
            <App />
        </HashRouter>
    </Provider>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
