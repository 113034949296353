/**
 * Project Page
 *
 * Contains the pilot projects that exemplify the climate strategies.
 */

import React, {Fragment} from 'react';
import {inject, observer} from 'mobx-react';

import ContentPage from '../../../components/ContentPage';
import {StrategyPageContent} from '../../strategies/strategy/Strategy';
import ReactMarkdown from "react-markdown";
import CarrotButton from "../../../components/Navigation/CarrotButton";


@inject('store')
@observer
class ProjectPage extends React.Component {

    constructor(props) {
        super(props);

        try {
            this.project = props.store.getProject(props.match.params.project);
        } catch (e) {
            console.error(e);
            this.props.history.push('/projects');
            // window.location.href = '/projects';
        }

        this.state = {
            //TODO -- how to set default correctly for project
            // threat: {name: 'Extended Drought', image: 'Diagrams_After Workshop_SLC-drought-01.jpg'},
        };
    }

    showThreat(threat) {
        this.setState({threat});
        const element = document.getElementById('threat-title')
        if (element) element.scrollIntoView({behavior: "smooth"});
    }

    displayName(projectThreat, short) {
        const {store} = this.props;
        const threat = store.threats[projectThreat.name];
        if (short) return threat.displayNameShort || threat.displayName || projectThreat.name;
        return threat.displayName || projectThreat.name;
    }

    render() {
        const {store} = this.props;
        if (!this.state.threat) {
            this.setState({threat: this.project.threats.find(t=>t.primary)});
            return null;
        }
        const selectedThreat = this.state.threat;

        const threatContent = store.threatContent[selectedThreat.name];

        const abstract = threatContent ? threatContent.abstract : 'TODO';

        const projectKey = this.props.match.params.project;

        const impactsAll = this.project.threats.sort((a,b)=> this.displayName(a).length - this.displayName(b).length).map((threat, i) => (
            <Fragment>
                {i !== 0 && ', '}
                {i === this.project.threats.length - 1 && 'and '}
                <button onClick={() => this.showThreat(threat)}>
                    {this.displayName(threat)}
                </button>
            </Fragment>
        ));


        const impactsOther = this.project.threats.filter(t => t!==this.state.threat).sort((a,b)=> this.displayName(a).length - this.displayName(b).length).map((threat, i) => (
            <Fragment>
                {i !== 0 && ', '}
                {/*{i === this.project.threats.length - 1 && 'and '}*/}
                <button onClick={() => this.showThreat(threat)}>
                    {this.displayName(threat)}
                </button>
            </Fragment>
        ));


        return (
            <ContentPage className="ProjectPage">
                <h1>{this.project.title}</h1>
                <h3 className="organization">{this.project.organization}</h3>
                <h2 className="location">{this.project.location}</h2>

                <ReactMarkdown children={this.project.header} linkTarget={'_blank'}/>

                <p className="threats">
                    Climate change has impacted the park through {impactsAll}.
                </p>

                <h2 id={'threat-title'} style={{color: store.getThreatColor(selectedThreat)}}>{this.displayName(selectedThreat)}</h2>

                <div className="image-container"
                     style={{backgroundImage: `url("./img/projects/${selectedThreat.image}")`}}>
                    <div className="aspect-maintainer"/>
                </div>

                <ReactMarkdown children={selectedThreat.markdown} linkTarget={'_blank'}/>
                <StrategyPageContent project={projectKey} store={this.props.store}
                                     threatName={selectedThreat.name}/>

                <p className="threats footer">
                    Also view strategies for: {impactsOther}
                </p>
                <div className={'map-link'}>
                    <CarrotButton text={`Map "${this.displayName(selectedThreat, true)}"`} onClick={() => this.props.history.push(`/map/${store.serialize(selectedThreat.name)}`)}/>
                    <CarrotButton text={'Show all strategies'} onClick={() => this.props.history.push(`/strategies`)}/>
                    <CarrotButton text={'Suggest a strategy'} onClick={() => this.props.history.push(`/suggest-strategy`)}/>
                </div>
            </ContentPage>
        );
    }

}


export default ProjectPage;
