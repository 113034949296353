/**
 * AppStore
 *
 * App views
 */

import {action, observable} from 'mobx';

import threatsContent from './assets/content/threats';
import strategiesContent from './assets/content/strategies.json';
import countyLocations from './assets/content/countyLocations.json';
import aboutPath from './assets/content/markdown/About 6e9d20bedecb4ec9935555261e276080.md';
import projectsContent from './assets/content/projects';


export default class AppStore {

    constructor() {
        for (let threat in this.threats) {
            fetch(this.threats[threat].markdown)
                .then(res => res.text())
                .then(content => this.threatContent[threat] = new ThreatContent(content + '\n'));//markdown needs a final linefeed to render links correctly
        }

        fetch(aboutPath)
            .then(res => res.text())
            .then(content => this.pageContent['about'] = content);
    }

    threatContent = observable({});
    pageContent = observable({});
    selectedCounty = observable([]);

    projects = projectsContent;

    // @observable aboutContent = 'Loading...';
    //
    // @action setAbout(value) {
    //     console.log('SET ABOUT');
    //     this.aboutContent = value;
    // }

    @action
    setSelectedCounty(value) {
        console.log('SET Selected', value);
        this.selectedCounty = value;
    }

    findCountyMatch(id) {
        return countyLocations[id];
    }

    get aboutContent() {
        return this.pageContent['about'];
    }

    get strategies() {
        return strategiesContent;
    }

    get countyList() {
        return Object.keys(countyLocations).map(k => countyLocations[k]);
    }

    get categorizedThreats() {
        return threatsContent;
    }

    strategiesByThreat(project) {
        return this.strategies.reduce((x, curr) => {
            if (!(curr['Threats'] in x)) {
                x[curr['Threats']] = []
            }
            if (project) {
                if (curr[`${project}?`] === 'x') {
                    let overrideText = curr[`${project} text`];
                    if (overrideText) {
                        x[curr['Threats']].push({...curr, ['Strategic Response']: overrideText});
                    } else {
                        x[curr['Threats']].push(curr);
                    }
                }
            } else {
                x[curr['Threats']].push(curr);
            }

            return x;
        }, {});
    }

    get threats() {
        return Object.values(this.categorizedThreats)
            .map(threat => threat.strategies)
            .reduce((a, b) => ({...a, ...b}));
    }

    getThreatForLayer(layerId) {
        let found = Object.keys(this.threats).find((threatId) => {
            let threat = this.threats[threatId];
            return (threat.layer === layerId || threat.fieldName === layerId)
        });
        return found && {id: found, threat: this.threats[found]};
    }

    getProject(project) {
        if (this.projects) {
            if (project in this.projects) {
                return this.projects[project];
            } else {
                throw new Error(`${project} is not found`);
            }
        }
    }

    getStrategiesByCategory(strategies, category) {
        return strategies.reduce((x, curr) => {
            if (!x[curr['Category']]) {
                x[curr['Category']] = [];
            }
            x[curr['Category']].push(curr);

            return x;
        }, {})[category];
    }

    getThreatColor(threat) {
        for (let category of Object.values(this.categorizedThreats)) {
            if (threat.name in category.strategies) {
                return category.color;
            }
        }
    }

    serialize(str) {
        return str ? encodeURIComponent(str.split(' ').join('-')) : null;
    }

    deserialize(str) {
        return str ? decodeURIComponent(str.split('-').join(' ')) : null;
    }

};


class ThreatContent {
    constructor(raw) {
        this.raw = raw;
    }

    get abstract() {
        return this.raw.split('\n')[2];
    }

    get body() {
        return this.raw.slice(this.raw.indexOf(this.abstract) + this.abstract.length, this.raw.length - 1);
    }
}
