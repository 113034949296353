/**
 * App
 *
 * Main application window.
 */

import React from 'react';
import { observer } from 'mobx-react';
import {Redirect, Route, Switch} from 'react-router';
import { withRouter } from 'react-router-dom';

import MapPage from './routes/map/Map';
import AboutPage from './routes/about/About';
import LandingPage from './routes/landing/Landing';
import ProjectsPage from './routes/projects/Projects';
import ProjectPage from './routes/projects/project/Project';
import StrategiesPage from './routes/strategies/Strategies';
import StrategyPage from './routes/strategies/strategy/Strategy';
import SuggestStrategy from "./routes/strategies/SuggestStrategy";

@observer
class App extends React.Component {
    render() {
        return (
            <div className="App">
                <Switch>
                    <Route exact path="/" component={LandingPage} />
                    <Route exact path="/map">
                        <Redirect to="/map/Wildfires" />
                    </Route>
                    <Route exact path="/map/:threat" component={MapPage} />
                    <Route exact path="/about" component={AboutPage} />
                    <Route exact path="/projects" component={ProjectsPage} />
                    <Route exact path="/projects/:project" component={ProjectPage} />
                    <Route exact path="/strategies" component={StrategiesPage} />
                    <Route exact path="/suggest-strategy" component={SuggestStrategy} />
                    <Route exact path="/strategies/:strategy" component={StrategyPage} />
                </Switch>
            </div>
        );
    }
}


export default withRouter(App);
