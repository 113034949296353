import React from 'react';

import DiminishedSnowpackPath from './markdown/Diminished Snowpack 95fb500c54f2489783f15a0ae0b72bd6.md';
import DroughtPath from './markdown/Drought 21aa2a09efb14fd99f6f21e1fd820e0f.md';
import ErosionPath from './markdown/Erosion ec57eaad01d84151ab422e3a39e3320d.md';
import ExtremePrecipitationEventPath from './markdown/Extreme Precipitation Event 90e82c9c7dd54ec2aa6f1f785e457739.md';
import ExtremeWindEventsPath from './markdown/Extreme Wind Events 779d6a8127f3491f996e80848b16a961.md';
import HeatWavePath from './markdown/Heat Wave 225d4541dfed491f87eafad33ec0207a.md';
import InlandFloodingPath from './markdown/Inland Flooding d123ff1f8aa94187af4188f58a817d8e.md';
import InvasiveAndNoxiousSpeciesPath
    from './markdown/Invasive and Noxious Species, Pest & Pathogen Outb 8efdd834ea4644d58b0b9a5cda71f933.md';
import LandslidesAndAvalanchesPath from './markdown/Landslides and Avalanches f22f17947f1745caad34b43050e94d2d.md';
import PoorAirQualityPath from './markdown/Air Pollution 63d753e2ace345cc8d3f852f7796333d.md';
import ReducedAnnualPrecipitationPath
    from './markdown/Reduced Annual Precipitation d7b0d92625ac494182382f2d0a40ad65.md';
import WaterQualityIssuesPath from './markdown/Water Quality Issues 4c8f1b9fff9e44509b3b62dde3c995a0.md';
import WildfiresPath from './markdown/Wildfires b3d9e422614f40ec8f10a1a1bc82466b.md';

const formats = {
    perc: (v) => `${Math.round(v)}%`,
    smart: (v) => `${Math.round(1000 * v) / 1000}`
};

const threats = {
    'Natural Disasters': {
        color: '#9E405A',
        strategies: {
            'Wildfires': {
                layer: "wildfire",
                markdown: WildfiresPath,
                legend: {
                    metric: 'Scenario: Projected change in number of very large fires through 2070',
                    format: formats.perc
                }
            },
            'Landslides / Avalanches': {
                displayName: 'Landslides & Avalanches',
                layer: "landslide",
                markdown: LandslidesAndAvalanchesPath,
                legend: {
                    metric: 'Snapshot: Total recorded landslides per 1000 sq miles',
                    format: (v) => `${Math.round(1000 * v)}`
                }
            },
            'Extended Drought': {
                layer: "drought",
                markdown: DroughtPath,
                legend: {
                    metric: 'Trend: Cumulative Drought Index (linear slope 2000-2019)',
                    format: formats.smart
                }
            },
            'Inland Flooding': {
                layer: "flood_risk",
                markdown: InlandFloodingPath,
                legend: {
                    metric: 'Snapshot: Properties at Risk 2020',
                    format: formats.perc
                }
            },
            'Extreme Wind Events': {
                layer: "wind_events",
                markdown: ExtremeWindEventsPath,
                legend: {
                    metric: 'Trend: Change in # extreme wind events for 12 years (2009-2020) vs (1997-2008)',
                    format: formats.smart
                }
            },
        }
    },
    'Abnormal Meteorological Disruptions': {
        color: '#E4838E',
        strategies: {
            'Heat Wave': {
                layer: "heat_events",
                markdown: HeatWavePath,
                legend: {
                    metric: 'Trend: Change in # heat events for 12 years (2009-2020) vs (1997-2008)',
                    format: formats.smart
                }
            },
            'Reduced Annual Precipitation': {
                displayNameShort: "Reduced Annual Precip.",
                layer: "reduced_ppt",
                markdown: ReducedAnnualPrecipitationPath,
                legend: {
                    higherIsBetter: true,
                    metric: 'Scenario: Projected change in summer precipitation by 2070',
                    format: (v) => `${Math.round(100 * v) / 100} in.`
                }
            },
            'Extreme Precipitation Event': {
                displayName: "Extreme Precipitation Events",
                displayNameShort: "Extreme Precip. Events",
                layer: "rain_events",
                markdown: ExtremePrecipitationEventPath,
                legend: {
                    metric: 'Trend: Change in # extreme precipitation events for 12 years (2009-2020) vs (1997-2008)',
                    format: formats.smart
                }
            },
        }
    },
    'Compounding Environmental Effects': {
        color: '#D35779',
        strategies: {
            'Diminishing Snowpack': {
                layer: "snowpack",
                markdown: DiminishedSnowpackPath,
                legend: {
                    higherIsBetter: true,
                    metric: 'Trend: Change in inches of snow pack (linear slope 2000-2019)',
                    format: formats.smart
                },
            },
            'Invasive Species, Pest and Pathogen Outbreaks': {
                displayName: "Invasive and Noxious Species, Pest & Pathogen Outbreaks",
                displayNameShort: "Invasive Species & Pests",
                layer: "invasive_species",
                markdown: InvasiveAndNoxiousSpeciesPath,
                legend: {
                    metric: 'Model: future certainty of select invasive species',
                    format: formats.smart
                },
            },
            // 'Pest and Pathogen Outbreaks': {
            //     markdown: PestAndPathogenOutbreaksPath,
            // },
            // 'Diminishing Aquifer / Water Resources': {
            //     markdown: Water,
            // },
            'Erosion': {
                layer: "erosion",
                markdown: ErosionPath,
                legend: {
                    metric: 'Model: Erosion risk index (1:low - 5:high)',
                    format: formats.smart
                },
            },
            'Water Quality Issues': {
                layer: "water-quality",
                fieldName: "water",//layer doesn't match fieldName because 'water' not allowed in MapBox
                markdown: WaterQualityIssuesPath,
                legend: {
                    metric: 'Snapshot: EPA water environmental quality index',
                    format: formats.smart
                },
            },
            'Air Pollution': {
                // displayName: "Air Pollution",
                layer: "air_quality",
                markdown: PoorAirQualityPath,
                legend: {
                    higherIsBetter: true,
                    metric: 'Trend: Change in Median Air Quality Index (linear slope 2000-2019)',
                    format: formats.smart
                },
            },
        }
    },
};


export default threats;
