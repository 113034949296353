// Threats
// Wildfires
// Landslides / Avalanches
// Extended Drought
// Inland Flooding
// Extreme Wind Events
// Heat Wave
// Reduced Annual Precipitation
// Extreme Precipitation Event
// Days with Poor Air Quality
// Diminishing Snowpack
// Erosion
// Water Quality Issues
// Invasive Species
// Pest and Pathogen Outbreaks
// Diminishing Aquifer / Water Resources
// Future threats
// Earthquake

const projects = {
    "SLC": {
        "title": "Glendale Water Park Redevelopment",
        "location": "Salt Lake City, UT",
        "organization": "Salt Lake City Public Lands",
        "quote": "Fluctuating river levels and drought conditions are the main climate change challenges here. With lower tree canopy, the urban heat island will also be more extreme.",
        "size": "large",
        "header": "The 17-acre Glendale Water Park site was formerly a regional attraction that drew crowds from all over the country. In 2018, the water park was closed and has since sat vacant. Situated along the Jordan River and one of the longest regional trails in the country (53 miles total), the site has the potential to once again become a community asset. The city will initiate demolition of the old water park structures soon, and along with a community planning effort, new priorities will be identified for the site.",
        "threats": [
            {
                "name": "Air Pollution",
                "image": "Diagrams_After Workshop_SLC-air quality-01.jpg",
                "markdown": ""
            },
            {
                "name": "Extended Drought",
                "image": "Diagrams_After Workshop_SLC-drought-01.jpg",
                "markdown": "### Issue\n\nSalt Lake City is currently experiencing a “D3” level drought which is identified and labeled as an extreme drought (USDM 2020-2021) and has impacted the urban tree canopy as well as other vegetation throughout the park. The existing spray-head irrigation system in Glendale Water Park distributes to all areas equally at the same time regardless of weather conditions, timing, or landscape type and is especially ineffective with tall vegetation. Replacing existing mown turf with alternative, low-mow options would be a maintenance shift, requiring new policies, procedures, and staff training. Areas of disturbance where alternative turfs are installed can be prone to invasive species invasion. In addition, the community can often perceive these landscapes as unkempt or forgotten.\n\n### Opportunity\n\nThe SLC Parks and Public Lands department can create a thriving urban tree canopy and understory while simultaneously using less water. Through careful species selection and waterwise irrigation methods, they can reduce their water demands while ensuring the landscape is well maintained."
            },
            {
                "name": "Extreme Wind Events",
                "image": "Diagrams_After Workshop_SLC-extreme wind event-01.jpg",
                "markdown": "### Issue\n\nSalt Lake City is losing thousands of mature trees due to extreme wind events, especially during Utah’s dry seasons.\n\n### Opportunity\n\nA city-wide urban tree plan that includes a variety of species that have adapted to climate change will create a more resilient urban canopy and provide additional benefits to the community."
            },
            {
                "name": "Invasive Species, Pest and Pathogen Outbreaks",
                "image": "Diagrams_After Workshop_SLC-invasives-01.jpg",
                "markdown": ""
            },
            {
                "name": "Diminishing Snowpack",
                "image": "Diagrams_After Workshop_SLC-snowpack los-01.jpg",
                "markdown": "### Issue\n\nMost of Utah is seeing below-average snowpack in recent years due to multiple reasons, including drought and rising temperatures.\n\n### Opportunity\n\nParks should seek ways to save water to prevent diminishing snowpack."
            },
            {
                "name": "Water Quality Issues",
                "image": "Diagrams_After Workshop_SLC-water quality-01.jpg",
                "primary": true,
                "markdown": "### Issue\n\nAs a key feature of the site, the Jordan River is naturally high in salinity and experiences high pollutant loads in the form of trash. The drainage area that encompasses the site has a high percentage of impervious area that directly drains to the Jordan River, contributing to the poor water quality.\n\n### Opportunity\n\nBy incorporating more green space and parks throughout the drainage area of the Jordan River, as well as reducing the impervious surfaces at Glendale Water Park, , the runoff and contamination into the Jordan River will be reduced. In addition, the SLC Parks and Public Lands department can engage the public in clean-ups and education campaigns to raise awareness of the poor water quality."
            },
            {
                "name": "Wildfires",
                "image": "Diagrams_After Workshop_SLC-wildfire-01.jpg",
                "markdown": "### Issue\n\nWildfires in Utah normally occur due to dry conditions and human activities. With the extended drought, wildfires are an increasing risk.\n\n### Opportunity\n\nParks with primarily herbaceous-based landscapes should implement design and management solutions to prevent wildfires."
            }
        ]
    },
    "Denver": {
        "title": "West Bar Val Wood Park",
        "location": "Denver, CO",
        "organization": "Denver Parks and Recreation",
        "quote": "Valverde is one of the most severe heat island hot spots within Denver. The neighborhood is bordered by I-25 and Highway 6 which causes additional air pollution concerns.",
        "size": "medium",
        "header": "West Bar Val Wood Park is an existing large neighborhood park located in the Valverde Neighborhood, which has one of the more severe heat island hot spots within the City of Denver. Main features include large active fields, a small playground and passive natural landscapes. The park is heavily used by the adjacent communities and is easy to access through walking. The park also functions as a detention pond for the area.",
        "threats": [
            {
                "name": "Extended Drought",
                "image": "Diagrams_After Workshop_DENVER-extended drought-01.jpg",
                "markdown": "### Issue\n\nDrought conditions in Colorado were among the worst in the United States in 2020.\n\n### Opportunity\n\nTo save water, Denver uses water consumption as a metric for ecological consideration. Since West Bar Val Wood Park is currently covered mostly by turf, but could consider a balance of native planting beds with turf to reduce the overall water usage."
            },
            {
                "name": "Inland Flooding",
                "image": "Diagrams_After Workshop_DENVER-inland flooding-01.jpg",
                "primary": true,
                "markdown": "### Issue\n\nWest Bar Val Wood Park is located within the South Platte River floodplain and is periodically flooded. The park is a localized lowpoint to surrounding development. Impervious and high potential runoff surfaces (turfgrass, compacted soils) to the north increases the potential for inland flooding during extreme precipitation events. The park is currently used as a stormwater detention facility for the region, while still providing active recreation when the water is drained. After a storm event, the fields are partially soggy and require increased maintenance effort. Extreme precipitation would require longer recovery periods and maintenance for the site.\n\n### Opportunity\n\nMaintain the functionality as a detention pond but consider diversifying the uses and plant materials of the park in the lowpoint."
            },
            {
                "name": "Invasive Species, Pest and Pathogen Outbreaks",
                "image": "Diagrams_After Workshop_DENVER-invasive species-01.jpg",
                "markdown": ""
            },
            {
                "name": "Heat Wave",
                "image": "Diagrams_After Workshop_DENVER-urban heat-01.jpg",
                "markdown": "### Issue\n\nDenver’s heat island is among the highest in the United States, and Valverde Neighborhood is one of the most severe hot spots in Denver. Summers are becoming more hot and the temperature swings dramatically, sometimes 65 degrees in a single day.\n\n### Opportunity\n\nAdjacent to a large industrial parcel with few trees, West Bar Val Wood Park should be considered as a site that can help reduce heat island impact for the neighborhood. Maintaining existing canopy on the site is limited to the southwest corner of the park."
            },
            {
                "name": "Water Quality Issues",
                "image": "Diagrams_After Workshop_DENVER-water quality-01.jpg",
                "markdown": "### Issue\n\nThe existing park functions as both a recreation/sports field and stormwater retention area. Currently the park relies on maintenance procedures and existing planting for water quality of the stormwater.\n\n### Opportunity\n\nImplement a holistic water quality approach that considers existing programs and functions while enhancing stormwater retention practices. This approach would consider future development or park programing, improved maintenance procedures, and any newly developed plant schedule."
            }
        ]
    },
    "Evanston": {
        "title": "Bear Meadows Park",
        "location": "Evanston, WY",
        "organization": "Evanston Parks and Recreation District",
        "quote": "Here in Wyoming we have wind 85% of the time and last fall we had a windstorm with hurricane force winds that took down around 30 trees just in the parks and Cemetery.",
        "size": "small",
        "header": "The Bear Meadows Park is a 6-acre grassy field located in the center of Evanston, WY along Bear River.The space is utilized all year round, as it can be easily programmed for various activities, such as soccer, baseball, and cross-country skiing. Bear Meadows Park also sits on the Bear River Corridor, which is the largest wildlife migration corridor in Evanston and plays an important role in the regional ecosystem. As a heavily used civic space and an important part of the ecological corridor, Bear Meadows has great potential to function as both green infrastructure and an active city park for the community.",
        "threats": [
            {
                "name": "Diminishing Snowpack",
                "image": "Diagrams_After Workshop_EVANSTON-diminished snowpack-01.jpg",
                "markdown": "### Issue\n\nEvanston’s current winter is much warmer compared to several years ago. Additionally, the area experiences extreme temperature swings within a single day. At the regional scale, snowpack is melting fast because of rising temperatures and water shortages.\n\n### Opportunity\n\nThe park district should seek ways to save water and look at ways to diversify programming during winter months."
            },
            {
                "name": "Extended Drought",
                "image": "Diagrams_After Workshop_EVANSTON-extended drought-01.jpg",
                "markdown": "### Issue\n\nDrought has impacted Wyoming for years, and as a result trees and vegetation are stressed in Evanston.\n\n### Opportunity\n\nThe park district can help increase the urban tree canopy and understory habitats; through careful species selection and waterwise irrigation methods, Evanston can achieve beautiful parks while also reducing water demands."
            },
            {
                "name": "Extreme Precipitation Event",
                "image": "Diagrams_After Workshop_EVANSTON-extreme precipitation-01.jpg",
                "markdown": "### Issue\n\nThe number of extreme precipitation events in Wyoming has been above average during recent years. Specifically, Evanston is experiencing heavier spring precipitation and a shift of precipitation from snow to rain. The clay-based soil in the Bear Meadow Park results in slow drainage during big storm events and increases the potential for flooding.\n\n### Opportunity\n\nBy including green infrastructure and multi-functional spaces, Bear Meadow Park can adapt to these extreme weather events."
            },
            {
                "name": "Inland Flooding",
                "image": "Diagrams_After Workshop_EVANSTON-inland flooding-01.jpg",
                "markdown": "### Issue\n\nWhile the site does not currently experience inland flooding from Bear River, the site could become more vulnerable to flooding with climate change and an increase to impervious ground cover within the adjacent watershed.\n\n### Opportunity\n\nClimate resilience strategies should be incorporated into a future master plan that looks at the site holistically."
            },
            {
                "name": "Invasive Species, Pest and Pathogen Outbreaks",
                "image": "Diagrams_After Workshop_EVANSTON-invasive species-01.jpg",
                "primary": true,
                "markdown": ""
            },
            {
                "name": "Water Quality Issues",
                "image": "Diagrams_After Workshop_EVANSTON-water quality-01.jpg",
                "markdown": "### Issue\n\nThe northeast corner of Bear Meadow Park was formerly used as an old lumber yard, which has the potential for point source pollution to Bear River.\n\n### Opportunity\n\nThe park has the potential to mitigate pollutant loads to the Bear River. The site could work to increase water quality of surrounding site runoff by preventing polluted water from flowing into the river."
            },
            {
                "name": "Wildfires",
                "image": "Diagrams_After Workshop_EVANSTON-wild fires-01.jpg",
                "markdown": "### Issue\n\nMost wildfires in Wyoming happen around late summer and early fall. With a primarily herbaceous-based landscape along the edges, Bear Meadow Park is susceptible to wildfires.\n\n### Opportunity\n\nFuture design and management should include strategies to prevent wildfires."
            }
        ]
    }
};

export default projects;
