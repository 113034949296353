/**
 * Strategies Page
 *
 * Contains the climate strategies index.
 */

import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import ContentPage from '../../components/ContentPage';


@inject('store')
@observer
class StrategiesPage extends React.Component {

    render() {
        const { categorizedThreats: threats, serialize } = this.props.store;

        return (
            <ContentPage className="StrategiesPage">
                <div className="header">
                    <h1>Find Strategies</h1>
                    Click on a threat below to find potential solutions for your park.
                </div>

                <ul className="strategies">
                    {Object.entries(threats).map(([categoryTitle, category]) => (
                        <li key={categoryTitle}>
                            <h2 style={{ color: category.color }}>{categoryTitle}</h2>

                            <ul className="strategy-list">
                                {Object.keys(category.strategies).map(strategy => (
                                    <li key={strategy}>
                                        <Link to={`/strategies/${serialize(strategy)}`} style={{ background: category.color }}>
                                            {category.strategies[strategy].displayName || strategy}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </ContentPage>
        );
    }

}


export default StrategiesPage;
