import React, {useState} from 'react';
import {useForm, ValidationError} from "@formspree/react";
import CarrotButton from "../Navigation/CarrotButton";


export default function ContactForm({type, includeUrl}) {
    const [state, handleSubmit] = useForm("xnqlqkok");
    if (state.succeeded) {
        return <div>
            <p>Thanks for sharing!</p>
            {/*<p>*/}
            {/*    <CarrotButton text={'Suggest another'} onClick={() => this.props.history.push(`/suggest-strategy`)}/>*/}
            {/*</p>*/}
        </div>;
    }
    return (
        <form className={'ContactForm'} onSubmit={handleSubmit}>
            <input type="hidden" name="type" value={type}/>
            <div>
                <label htmlFor="email">
                    Your Email:
                </label>
                <input
                    id="email"
                    type="email"
                    name="email"
                />
                <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                />
            </div>
            <div className={'form-row'}>
                <textarea
                    id="message"
                    name="message"
                />
                <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                />
            </div>
            {includeUrl && <div className={'form-row'}>
                <label htmlFor="url">
                    Reference links (optional):
                </label>
                <input
                    id="url"
                    type="url"
                    name="url"
                />
            </div>
            }
            <div className={'form-row'}>
                <button type="submit" disabled={state.submitting}>
                    Submit
                </button>
            </div>
        </form>
    );
}
