import React from 'react';
import {HashRouter, Link} from "react-router-dom";
import {interpretLayerStyleColors} from "./legendUtils";
import chroma from "chroma-js";

const numCounties = 356;

function RankBar(props) {
    const {rank, selected, color} = props;
    const along = rank / numCounties;
    const width = 35;
    return <div className={'RankBar' + (selected ? ' selected' : '')}>
        <div className={'bar'} style={{width: width}}/>
        <div className={'marker'} style={{backgroundColor: color, left: along * (width - 4)}}/>
    </div>;
}

function calcColor(legendData, val) {
    if (!legendData) return '#000000';
    const {layerStyles} = legendData;

    let color1, color2;
    let ramp = interpretLayerStyleColors(layerStyles);
    ramp.forEach(({num, color}, i) => {
        if (!color1 || val > num) {
            color1 = {color, num};
            color2 = null;//color2 will be assigned to the next ramp item
        } else {
            if (!color2) {
                color2 = {color, num};
            }
        }
    })
    if (color1 && !color2) {
        return color1.color;//case when value > max
    }
    if (!color1 || !color2) {
        return '#000000';
    }
    const along = (val - color1.num) / (color2.num - color1.num);

    return chroma.mix(color1.color, color2.color, along, 'lab').hex();
}


function RowItem(props) {
    const {store, selected, legendData} = props;
    const {id, threat} = store.getThreatForLayer(props.field);
    let displayName = threat.displayNameShort || threat.displayName || id;
    let color;
    if (selected) {
        color = calcColor(legendData, props.rank.v);
    }
    return <div className={'RowItem'}>
        <RankBar selected={selected} rank={props.rank.i} color={color}/>
        <div className={'item-name'}>
            {selected ? <div className={'selected'}>({legendData.format(props.rank.v)}) {displayName}</div> :
                <Link to={`/map/${store.serialize(id)}`}>{displayName}</Link>
            }
        </div>
    </div>;
}

export default class MapPopupList extends React.Component {

    constructor(props) {
        super(props);

    }

    sortedRanks() {
        const {county, activeLayer, store} = this.props;
        const sorted = [];
        const activeThreat = store.getThreatForLayer(activeLayer);

        Object.keys(county.ranks).forEach((k) => {
            const {threat} = store.getThreatForLayer(k);
            sorted.push({
                field: k,
                rank: {
                    i: threat.legend.higherIsBetter ? numCounties - county.ranks[k].i : county.ranks[k].i,
                    v: county.ranks[k].v
                },
                selected: activeThreat.threat.layer === k || activeThreat.threat.fieldName === k
            })
        });
        sorted.sort((a, b) => b.rank.i - a.rank.i);

        return sorted;

        // const rankings = [...county.rankings];

    }

    render() {
        const {county, store, legendData} = this.props;
        if (!county || !legendData) return null;
        return (
            <div className="MapPopupList">
                <div className={'main-info'}>{county.name}</div>
                <HashRouter>
                    {this.sortedRanks().map(item => <RowItem store={store} legendData={legendData}
                                                             key={item.field} {...item}/>)}
                </HashRouter>
            </div>
        );
    }
}
