import React, { useState } from 'react';


export default function Closable({ children, closed, title }) {
    const [isClosed, setIsClosed] = useState(closed);

    return (
        <div className={`Closable ${isClosed ? 'closed' : 'open'}`}>
            <div className="Closable__title" onClick={() => setIsClosed(!isClosed)}>{title}</div>
            <div className="Closable__content">
                {children}
            </div>
        </div>
    );
}
