export const interpretLayerStyleColors = (layerStyles) => {
    const ans = [];
    if (!layerStyles || layerStyles.length < 3) return ans;
    for (let i = 3; i < layerStyles.length; i += 2) {
        const num = layerStyles[i];
        const color = layerStyles[i + 1];
        ans.push({num, color});
    }
    return ans;
};
